import React from 'react';
import { HashLink as Link }
from 'react-router-hash-link';
import OwlCarousel from 'react-owl-carousel2';
import AOS from 'aos';
import Contact from './contact';

class Home extends React.Component {
render() {
AOS.init({
offset: 0,
        duration: 800,
        easing: 'ease-in-quad',
        delay: 100,
});
        const options = {
        loop: true,
                stagePadding: 100,
                margin: 20,
                nav: false,
                responsive: {
                0: {
                items: 2
                },
                        600: {
                        items: 3
                        },
                        1000: {
                        items: 4
                        }
                }
        };
        return( 
                <div>

    <section id="home" className="home">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="main-banner">
                        <div className="d-sm-flex justify-content-between">
                            <div data-aos="zoom-in-up">
                                <div className="banner-title">
                                    <h3 className="font-weight-medium"> Start your e-shop with a fast and easy to use App with an elegant design.
                                    </h3>
                                </div>
                                <p className="mt-3">Multi-Platform App, Android, iOS and website version. Multiple payment
                                    <br/>
                                    options and an administrator control panel.
                                    Know more about all Features ...
                                </p>
                                <Link
                                    smooth 
                                    to="/#features">
                                <a href="" className="btn btn-success mt-3 lm-btn">Learn more</a>
                                </Link> 
                            </div>
                            <div className="mt-5 mt-lg-0">
                                <a href="https://play.google.com/store/apps/details?id=com.ecomerso.store&hl=en" target="blank">
                                    <img src={require("./images/group_1.png")} alt="ecomerso" className="img-fluid" data-aos="zoom-in-up" />
                                </a>
                            </div>
                            <div className="mt-5 mt-lg-0">
                                <a href="https://testflight.apple.com/join/snEr9W4V" target="blank">
                                    <img src={require("./images/group_2.png")} alt="ecomerso" className="img-fluid" data-aos="zoom-in-up" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="our-services" id="features">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h5 className="text-dark">App Features</h5>
                    <h3 className="font-weight-medium text-dark mb-5">Ecomerso is a fast,
                        simple, secure and sophisticated ecommerce app</h3>
                </div>
            </div>
            <div className="row" data-aos="fade-up">
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/translation.svg")} alt="multilingual" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">Multi Languages
                        </h6>
                        <p>Swedish & English language version available for all platforms. It was developed to add many other languages.
                        </p>
                    </div>
                </div>
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box"   data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/betala.svg")} alt="betalningssätt" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">Several common payment methods.
                        </h6>
                        <p>Payson payment gateway support so that the customer can pay with invoice, trolley, bank account and part payment. 
                        </p>
                    </div>
                </div>
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/admin.svg")} alt="digital-strategy" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">Administrator control panel.
                        </h6>
                        <p>An administrator control panel where you can control the entire contents of Ecomerso i.e. Pages, Menu, Products, Settings <a href="#about" className="lm-btn"> and much more ....</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row" data-aos="fade-up">
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box  pb-lg-0" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/warehouse.svg")} alt="warehouse" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">Multi-warehouse
                        </h6>
                        <p>Assign warehouses to shipping zones to automatically 
                            calculate availability based on local stock levels

                        </p>
                    </div>
                </div>
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box pb-lg-0" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/currency.svg")} alt="currency" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">
                            Multi-channel / multi-currency
                        </h6>
                        <p>Create channels with different currencies, pricing, and product availability

                        </p>
                    </div>
                </div>
                <div className="col-sm-4 text-center text-lg-left">
                    <div className="services-box pb-0" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                        <img src={require("./images/saving-strategy.svg")} alt="saving-strategy" data-aos="zoom-in" />
                        <h6 className="text-dark mb-3 mt-4 font-weight-medium">
                            Flexible products
                        </h6>
                        <p>No more compromises and workarounds. Shape your products the way your 
                            business works and integrate with your accounting and inventory systems.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="our-process" id="about">
        <div className="container">
            <div className="row">
                <div className="col-sm-4" data-aos="fade-up">
                    <h5 className="text-dark">Manage the App with</h5>
                    <h4 className="font-weight-medium text-dark">Administration panel</h4>
                    <h5 className="text-dark mb-3">Order management - Cart and checkout - Back office</h5>
                    <div className="d-flex justify-content-start mb-3">
                        <img src={require("./images/tick.png")} alt="tick" className="mr-3 tick-icon" />
                        <p className="mb-0">Multi-carrier shipping: Let clients choose between multiple carriers and delivery methods. Set separate rates for different countries.</p>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <img src={require("./images/tick.png")} alt="tick" className="mr-3 tick-icon" />
                        <p className="mb-0">Payment integration: Collect payments using global providers like Paypal, Braintree, and Stripe.</p>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <img src={require("./images/tick.png")} alt="tick" className="mr-3 tick-icon"  />
                        <p className="mb-0">Staff:
                            Manage access privileges by assigning staff members to role-based permission groups.</p>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <img src={require("./images/tick.png")} alt="tick" className="mr-3 tick-icon"  />
                        <p className="mb-0">Refunds and returns:
                            Handle and document refunds and returns directly on the order fulfillment page.</p>
                    </div>
                    <div className="d-flex justify-content-start">
                        <img src={require("./images/tick.png")} alt="tick" className="mr-3 tick-icon"  />
                        <p className="mb-0">Sales and vouchers:
                            Create sales and vouchers in a dedicated dashboard module and drive conversion rates.</p>
                    </div>

                </div>
                <div className="col-sm-8 text-right" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <img src={require("./images/admin.png")} alt="idea" className="img-fluid" />
                </div>
            </div>
        </div>
    </section>
    <section className="our-projects" id="projects">
        <div className="container">
            <div className="row mb-5">
                <div className="col-sm-12">
                    <div className="d-sm-flex justify-content-between align-items-center mb-2">
                        <h3 className="font-weight-medium text-dark ">App screenshots</h3>

                    </div>
                </div>
            </div>
        </div>
        <div className="mb-5" data-aos="fade-up">
            <OwlCarousel options={options} className="owl-carousel-projects owl-carousel owl-theme">
                <div className="item">
                    <img src={require("./images/carousel/slider1.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider2.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider3.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider4.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider5.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider6.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider7.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider8.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider9.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider10.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider11.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider12.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider13.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider14.png")} alt="slider" />
                </div>
                <div className="item">
                    <img src={require("./images/carousel/slider15.png")} alt="slider" />
                </div>
            </OwlCarousel>
        </div>
    </section>
    <section className="pricing-list" id="plans">
        <div className="container">
            <div className="row" data-aos="fade-up" data-aos-offset="-500">
                <div className="col-sm-12">
                    <div className="d-sm-flex justify-content-between align-items-center mb-2">
                        <div>
                            <h3 className="font-weight-medium text-dark ">Checkout Pricing Plans</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row"  data-aos="fade-up" data-aos-offset="-300">
                <div className="col-sm-4">
                    <div className="pricing-box">
                        <img src={require("./images/startup.svg")} alt="starter" />
                        <h6 className="font-weight-medium title-text">Starter Business*</h6>
                        <h1 className="text-amount mb-4 mt-2">$99</h1>
                        <ul className="pricing-list">
                            <li>Ecomerso Web Store</li>
                            <li>Ecomerso Android App</li>
                            <li>Ecomerso Iphone App</li>
                            <li>Starting up guidance</li>
                            <li>Open Source Code</li>
                             <li>30 days money back guarantee</li>
                        </ul>
                        <a href="https://ecomerso.pay.clickbank.net/?cbitems=1"  className="btn btn-outline-danger">Purchase</a>                                      </div>
                </div>
                <div className="col-sm-4">
                    <div className="pricing-box selected">
                        <img src={require("./images/briefcase.svg")} alt="starter" />
                        <h6 className="font-weight-medium title-text">Up and Running</h6>
                        <h1 className="text-amount mb-4 mt-2">$299</h1>
                        <ul className="pricing-list">
                            <li>Ecomerso Web Store</li>
                            <li>Ecomerso Android App</li>
                            <li>Ecomerso Iphone App</li>
                            <li>Installation and Preparation**</li>
                            <li>Starting up guidance</li>
                            <li>App hosting</li>
                            <li>Open Source Code</li>
                            <li>30 days money back guarantee</li>


                        </ul>
                        <a href="#contact" className="btn btn-outline-danger">Contact us</a>
                     {/* <a  href="https://ecomerso.pay.clickbank.net/?cbitems=2" className="btn btn-outline-danger">Purchase</a> */}    	
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="pricing-box">
                        <img src={require("./images/premium.svg")} alt="starter" />
                        <h6 className="font-weight-medium title-text">Premium</h6>
                        <h1 className="text-amount mb-4 mt-2">$499</h1>
                        <ul className="pricing-list">
                            <li>Ecomerso Web Store</li>
                            <li>Ecomerso Android App</li>
                            <li>Ecomerso Iphone App</li>
                            <li>Installation and Preparation </li>
                            <li>Starting up guidance</li>
                            <li>App hosting</li>
                            <li>Custom Theme, Logo and Icons***</li>
                            <li>Open Source Code</li>
                             <li>30 days money back guarantee</li>
                        </ul>
                          <a href="#contact" className="btn btn-outline-danger">Contact us</a> 
                        {/* <a href="https://ecomerso.pay.clickbank.net/?cbitems=3" className="btn btn-outline-danger">Purchase</a> */}                          
                    </div>
                </div>
            </div>
            <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-offset="-300">
                *Direct download link will be availabe instantly when purchasing is completed successfully.<br/>
                **Installation takes five to ten business days depends on the work load after a complete purchasing.<br/>                
                ***Custom UI design includes logo and icons takes 15 to 20 business days after a complete purchasing. <br/>
                <span class="clickbank_disclaimer"><br/>ClickBank is the retailer of products on this site. CLICKBANK® is a registered trademark of Click Sales, Inc., a Delaware corporation located at 1444 S. Entertainment Ave., Suite 410 Boise, ID 83709, USA and used by permission. ClickBank's role as retailer does not constitute an endorsement, approval or review of these products or any claim, statement or opinion used in promotion of these products.
                <br />Files Formats of the Product are: python .py, javascript .js, typescript .ts and Json.
                </span>
            </div>
        </div>
                </section>
                <section className="contactus" id="contact">
        <div className="container">
            <div className="row mb-5 pb-5">
                <div className="col-sm-5" data-aos="fade-up" data-aos-offset="-500">
                    <img src={require("./images/contact.jpg")} alt="contact" className="img-fluid" />
                </div>
                <div className="col-sm-7" data-aos="fade-up" data-aos-offset="-500">
                    <h3 className="font-weight-medium text-dark mt-5 mt-lg-0">Got A Problem or Have a Question ! </h3>
                    <h5 className="text-dark mb-5">Feel free to contact us via our contact form</h5>
                    <Contact />
                </div>
            </div>
        </div>
    </section>
                </div>
                )
}
}

export default Home;