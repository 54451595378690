import React from 'react';

class TopPage extends React.Component {	
  
  
  render() {
	  
    return(
	<>
	<div className="topPage">
	<div className="container">
	<div className="row">
	<div className="col-sm-12">
	<div className="topPage-top">
	<h1 className="topPage-title">{this.props.pageTitle}</h1>
	</div>
	</div>
	</div>
	</div>
	</div>
	</>
	)
  }
}

export default TopPage;