import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';
import ecomersoLogo from './images/logo.svg';
import ecomersoLogoDark from './images/logo-dark.svg';


class Header extends React.Component {
	
	componentDidMount() {
		$(".navbar-toggler").click(function() {
			$('.collapsing').toggleClass('show');
			$('body').addClass("sidebar-overlay");
		});
		$("#mobile-menu-overlay, .close-menu, .nav-link").click(function() {
			$('.collapse').toggleClass('show');
			$('body').removeClass("sidebar-overlay");
		});
	
  }
  
  render() {
    return(
	<>
	<div id="mobile-menu-overlay"></div>
	<nav className="navbar navbar-expand-lg fixed-top">
			<div className="container">
				<a className="navbar-brand" href="#"><img src={ecomersoLogo} alt="Ecomerso" /></a>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"><i className="mdi mdi-menu"> </i></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarTogglerDemo01">
					<div className="d-lg-none d-flex justify-content-between px-4 py-3 align-items-center">
						<img src={ecomersoLogoDark} className="logo-mobile-menu" alt="logo" />
						<a href="javascript:;" className="close-menu"><i className="mdi mdi-close"></i></a>
					</div>
					<ul className="navbar-nav ml-auto align-items-center">
						<li className="nav-item active">
						<Link
						smooth 
						to="/#home">
							<a className="nav-link" href="#home">Home <span className="sr-only">(current)</span></a>
						</Link> 
						</li>
						<li className="nav-item">
						<Link 
						to="/#features"
						smooth	
						className="text-small text-white mx-2 footer-link">
							<a className="nav-link" href="#features">Features</a>
							</Link>
						</li>
					
						<li className="nav-item">
						<Link 
						to="/#projects"
						smooth	
						className="text-small text-white mx-2 footer-link">
							<a className="nav-link" href="#projects">Screenshots</a>
							</Link>
						</li>
						<li className="nav-item">
						<Link 
						to="/#plans"
						smooth	
						className="text-small text-white mx-2 footer-link">
							<a className="nav-link" href="#plans">Plans</a>
							</Link>
						</li>
                                                	<li className="nav-item">
						<Link 
						to="/#contact"
						smooth	
						className="text-small text-white mx-2 footer-link">
							<a className="nav-link" href="#contact">Contact us</a>
							</Link>
						</li>
						<div class="dropdown">
						<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Try it
						</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<a class="dropdown-item" href="http://store.ecomerso.com/" target="blank">Web Store</a>
							<a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.ecomerso.store&hl=en" target="blank">Google Play</a>
							<a class="dropdown-item" href="https://testflight.apple.com/join/snEr9W4V" target="blank">Apple Store</a>
						</div>
						</div>
					</ul>
				</div>
			</div>
		</nav>
		</>
	)
  }
}

export default Header;