import React from 'react';
import {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Header from './header';
import Home from './home';
import Privacy from './privacy';
import Terms from './terms';
import ScrollTopArrow from './scrollTopArrow';
// import Thanku from './thanku';






function App() {
  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  /*
  const clickbankBadget = () => {
  const script = document.createElement('script');

  script.src = "https://use.typekit.net/foobar.js";
  script.async = true;

  //document.body.appendChild(script);

  //return () => {
   //document.body.removeChild(script);
  //};
    return {__html: window.eval(script)
}; 

  }; */
    
    useEffect(() => {
  const script = document.createElement('script');

  script.src = "//cbtb.clickbank.net/?vendor=ecomerso";
  script.async = true;

  document.getElementById("clbBadget").appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []); 
  return (
  
  <Router>
    <div className="App">
		<ScrollTopArrow/>
	<Header/>
	<div className="page-body-wrapper">
	<Switch>
	<Route exact path="/">
            <Home />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
	   <Route path="/terms">
                 
            <Terms />
            </Route>
	 
        </Switch>
		</div>
	<footer className="footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<address>
								
								<div className="d-flex align-items-center">
									<p className="mr-4 mb-0">For Product Support, please contact the vendor	<a href="mailto:support@ecomerso.com" className="footer-link">Here</a></p>
									
								</div>
								<div className="d-flex align-items-center">
                                                                <p className="mr-4 mb-0">For Order Support, please contact ClickBank <a href="https://www.clkbank.com/" className="footer-link">Here</a></p>
								</div>
							</address>
							<div className="social-icons">
								<h6 className="footer-title font-weight-bold">
									Social Share
								</h6>
								<div className="d-flex">
									<a href="#"><i className="mdi mdi-github-circle"></i></a>
									<a href="#"><i className="mdi mdi-facebook-box"></i></a>
									<a href="#"><i className="mdi mdi-twitter"></i></a>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="col-sm-4">
									<h6 className="footer-title">Media</h6>
									<ul className="list-footer">
										<li><a href="#" className="footer-link">Home</a></li>
										<li><a href="#" className="footer-link">About</a></li>
										<li><a href="#" className="footer-link">Services</a></li>
										<li><a href="#" className="footer-link">Portfolio</a></li>
										<li><a href="#" className="footer-link">Contact</a></li>
									</ul>
								</div>
								<div className="col-sm-4">
									<h6 className="footer-title">Product</h6>
									<ul className="list-footer">
										<li><a href="#" className="footer-link">Digital Marketing</a></li>
										<li><a href="#" className="footer-link">Web Development</a></li>
										<li><a href="#" className="footer-link">App Development</a></li>
										<li><a href="#" className="footer-link">Design</a></li>
									</ul>
								</div>
								<div className="col-sm-4">
									<h6 className="footer-title">Company</h6>
									<ul className="list-footer">
										<li><a href="#" className="footer-link">Partners</a></li>
										<li><a href="#" className="footer-link">Investors</a></li>
										<li><a href="#" className="footer-link">Partners</a></li>
										<li><a href="#" className="footer-link">FAQ</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<p className="mb-0 text-small pt-1">© 2020 All rights reserved.</p>
                                                      
						</div>
						<div>
							<div className="d-flex">
							<Link to="/privacy" onClick={scrollTop} className="text-small text-white mx-2 footer-link">Privacy Policy</Link> 
							<Link to="/terms" onClick={scrollTop} className="text-small text-white mx-2 footer-link">Terms & Condeditions</Link>							
								<a href="#" className="text-small text-white mx-2 footer-link">Customer Support </a>
								<a href="#" className="text-small text-white mx-2 footer-link">Careers </a>

                                                         </div>
						</div>
                                                <div id="clbBadget"></div>
					</div>
				</div>
			</div>
		</footer>
    </div>
	</Router>
  );
}

export default App;
