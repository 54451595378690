import React, {Component} from 'react';

class Contact extends Component {
    constructor(props) {
	super(props);
	this.state = {
  	name: '',
  	email: '',
  	message: '',
	errors: []
	}
  }
    handleSubmit(e){
    e.preventDefault();
    var xhttp = new XMLHttpRequest();
    var data=  this.state;
    var comp = this;
    
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            if(this.responseText === "1"){
                alert("Thank you for your Message. We will get back to you shortly"); 
                comp.setState({name: '', email: '', message: ''})
            }else{  alert(this.responseText); 
            }
       }
    };

    xhttp.open("POST", "/contact.php/", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhttp.send("name="+data.name+"&email="+data.email+"&message="+data.message);
    //xhttp.send("name=omar&email=mr.omr86@gmail.com&message=ZZZZZZZZZZZZZZZZ");
  
    }

  resetForm(){
    
     this.setState({name: '', email: '', message: ''})
  }
  
  render() {
	return(
  	<div>
  	<form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
	<div className="row">
	<div className="col-sm-6">
  	<div className="form-group">
      	<label htmlFor="name">Name</label>
      	<input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
  	</div>
	</div>
	<div className="col-sm-6">
  	<div className="form-group">
      	<label htmlFor="exampleInputEmail1">Email address</label>
      	<input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
  	</div>
	</div>
	<div className="col-sm-12">
  	<div className="form-group">
      	<label htmlFor="message">Message</label>
      	<textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
  	</div>
	</div>
	<div className="col-sm-12">
  	<button type="submit" className="btn btn-primary">Submit</button>
	</div>
	</div>
  	</form>
  	</div>
	);
  }

  onNameChange(event) {
	this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	this.setState({message: event.target.value})
  }
}
							

export default Contact;